
.inquiry_item {
    cursor: pointer;
  }
  
.inquiry_item:hover {
    color: white !important;
background-color: #4e73df;
}

.inquiry_selected_buttons {
  cursor: pointer;
}

.inquiry_selected_buttons:hover {
  color: #4e73df !important;

}

.link_item {
  cursor: pointer;
  color: #4e73df !important;
}

.link_item:hover {
  text-decoration: underline;
}

