

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full-dialog-animation {
  animation-name: fullDialogAnimationKeyframes;
  animation-duration: 0.5s;
  
}

@keyframes fullDialogAnimationKeyframes {
  from {
    opacity: 0;
    margin-top: 2.5rem;
    /* height:  non; */
  }
  to {
    opacity: 1;
    margin-top: 0rem;
    /* height:fit-content; */
  }
}

.punchModeAnimation {
  background-color: rgba(87, 245, 66,0.5);
  animation: punchModeAnimationKeyframes 4s infinite;
  /* opacity: 0.5; */
}

.punchModeAnimationError {
  background-color: rgba(255, 92, 92,0.5);
  animation: punchModeAnimationErrorKeyframes 4s infinite;
  /* opacity: 0.5; */
}

@keyframes punchModeAnimationKeyframes {
  0% {background-color: rgba(87, 245, 66,0.5);}
  50% {background-color: rgb(0, 117, 23,0.5);}
  100% {background-color: rgb(87, 245, 66,0.5);}
}

@keyframes punchModeAnimationErrorKeyframes {
  0% {background-color: rgb(255, 92, 92,0.5);}
  50% {background-color:rgb(172, 24, 24,0.5);}
  100% {background-color: rgb(255, 92, 92,0.5);}
}